import { conditionnements } from './conditionnements';
import { contactMetaForContact, contactMetaKeys, contactMetas, contactMetasForContact } from './contact-meta';
import { contact, contactSalesRep, contacts } from './contacts';
import { docShipmentLines, docs } from './docs';
import { emailtemplates, emailtemplatesRender } from './email-templates';
import { files } from './files';
import { journals } from './journals';
import { meTasksCounter } from './me';
import { media, medias } from './medias';
import { newsletterSubscriptions, newsletterSubscriptionsForEmail } from './newsletter-subscriptions';
import { product, products } from './products';
import { requestQuotes } from './request-quotes';
import { tracking } from './shipments';
import { add, get, list } from './tasks';
import { add as addDocOverride, update as updateDocOverride } from './doc-overrides';
import { transactionsVatReport } from './transactions';
import { vatRates } from './vat-rates';

export type { Error, Response } from './response';

export const apiPdg = {
  files,
  medias,
  media,
  journals,
  emailtemplates,
  emailtemplatesRender,
  contact,
  contacts,
  contactSalesRep,
  products,
  product,
  contactMetas,
  contactMetasForContact,
  contactMetaForContact,
  contactMetaKeys,
  transactionsVatReport,
  vatRates,
  requestQuotes,
  docShipmentLines,
  tasks: {
    list: list,
    get: get,
    add: add,
  },
  docOverrides: {
    add: addDocOverride,
    update: updateDocOverride,
  },
  meTasksCounter,
  shipmentTracking: tracking,
  docs,
  conditionnements,
  newsletterSubscriptions,
  newsletterSubscriptionsForEmail,
}